import { useState, type ReactElement } from 'react'

import { ticketStore } from '@/store/tickets'
import { errorStore } from '@/store/error'
import { useUserPermissions } from '@/store/user'

import { usePutUpdateBatch } from '@/hooks/Tickets/Batch'

import { LinkIcon } from '@/components/Icons/LinkIcon'
import { DropDown } from '@/components/Global/DropDown'
import { Spinner } from '@/components/Global/Spinner'

import { type IBatch, type IFollower } from '@/types/Tickets'

import { hasClearance } from '@/utils/rbac'
import { HiOutlinePencil } from 'react-icons/hi2'
import { TbTrash } from 'react-icons/tb'
import { twMerge } from 'tailwind-merge'

interface BatchCardHeaderProps {
  batch: IBatch
  batchDropDownData: IFollower[]
  handleEditBatch: () => void
  handleDeleteBatch: () => void
}

export function BatchCardHeader({
  batch,
  handleEditBatch,
  handleDeleteBatch,
  batchDropDownData,
}: BatchCardHeaderProps): ReactElement {
  const [nextBatch, setNextBatch] = useState(
    batchDropDownData.find((follower) => follower.id === batch.follower.id),
  )

  const ticketsSold = batch.ticketSpecs?.reduce(
    (acc, ticketSpec) => acc + ticketSpec.numberOfSoldTickets!,
    0,
  )

  const { updateBatch, isLoading: isLoadingUpdateBatch } = usePutUpdateBatch()

  const { showError } = errorStore()
  const { updateBatch: updateBatchStore } = ticketStore()
  const { eventPermissions } = useUserPermissions()

  const canEditBatch = hasClearance(eventPermissions.tickets, 'EDITOR')

  const hasSoldTickets = batch.ticketSpecs?.some(
    (ticketSpec) => ticketSpec.numberOfSoldTickets! > 0,
  )

  const mappedDropDownData = batchDropDownData.map((dropDown) => {
    return { id: dropDown.id, value: `${dropDown.batchDescription}` }
  })

  async function handleSetNextBatchClick(nextBatch: IFollower): Promise<void> {
    const response = await updateBatch({
      batchId: batch.id!,
      followerId: nextBatch.id,
    })
    if (!response.status) {
      showError('Erro ao atualizar o lote. Tente novamente!')
      return
    }
    updateBatchStore({
      ...batch,
      follower: response.batch?.follower ?? {},
    })
    setNextBatch(nextBatch)
  }

  function handleDropDownOnChange(nextBatchId: number | undefined): void {
    nextBatchId = nextBatchId === -1 ? undefined : nextBatchId
    const nextFollower = batchDropDownData.find(
      (batch) => batch.id === nextBatchId,
    )
    void handleSetNextBatchClick(nextFollower!)
  }

  return (
    <header className="flex justify-between">
      <div className="flex flex-col">
        <span className="text-elipsis mb-2 line-clamp-1 overflow-hidden text-base font-black leading-4">
          {batch.description}
        </span>

        {batch.numberOfAvailableTickets! > 0 && (
          <strong className="mb-1 text-xs font-medium leading-3 text-text-secondary">
            {batch.numberOfAvailableTickets}{' '}
            {batch.numberOfAvailableTickets === 1 ? 'ingresso' : 'ingressos'}
          </strong>
        )}

        {batch.userLimit! > 0 && (
          <strong className="mb-1 text-xs font-medium leading-3 text-text-secondary">
            {batch.userLimit}{' '}
            {batch.userLimit === 1
              ? 'ingresso por usuário'
              : 'ingressos por usuário'}
          </strong>
        )}

        {ticketsSold !== undefined && (
          <strong
            className={twMerge(
              'mb-1 text-xs  leading-3 ',
              ticketsSold > 0
                ? 'font-semibold text-primary-main'
                : 'font-medium text-text-secondary',
            )}
          >
            {ticketsSold} venda{ticketsSold === 1 ? '' : 's'}
          </strong>
        )}

        {(batch.userLimit! === 0 || batch.numberOfAvailableTickets! === 0) && (
          <strong className="mb-4 text-xs font-medium leading-3 text-text-secondary">
            {`Sem limite ${
              batch.numberOfAvailableTickets === 0 ? 'geral' : ''
            } ${
              batch.userLimit! === 0 && batch.numberOfAvailableTickets === 0
                ? ' ou'
                : ''
            } ${batch.userLimit! === 0 ? 'por usuário' : ''}`}
          </strong>
        )}

        <div className="flex h-3 items-center gap-1">
          <LinkIcon />
          <span className="whitespace-nowrap text-xs">Próximo lote:</span>
          {canEditBatch ? (
            <DropDown
              dropDownData={mappedDropDownData}
              handleOnChange={handleDropDownOnChange}
              value={nextBatch?.batchDescription ?? ''}
            />
          ) : (
            <span className="text-xs font-bold">
              {nextBatch?.batchDescription ?? ''}
            </span>
          )}
          {isLoadingUpdateBatch && (
            <div className="ml-2">
              <Spinner
                borderWidth="border-[3px]"
                borderColor="border-primary-main/50"
                bottomBorderColor="border-b-primary-main"
                width="w-4"
                height="h-4"
              />
            </div>
          )}
        </div>
      </div>
      {canEditBatch && (
        <div className="flex h-5 items-center gap-2">
          {!hasSoldTickets! && (
            <TbTrash
              size={20}
              onClick={handleDeleteBatch}
              className="hover:cursor-pointer"
            />
          )}
          <HiOutlinePencil
            size={18}
            onClick={handleEditBatch}
            className="hover:cursor-pointer"
          />
        </div>
      )}
    </header>
  )
}
