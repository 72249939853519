import {
  useRef,
  useState,
  type ReactElement,
  type ReactNode,
  useEffect,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { SideBarOrganizations } from './SideBarOrganizations'
import { SideBarEvents } from './SideBarEvents'
import { SideBarEvent } from './SideBarEvent'
import { Header } from '../Global/Header'
import { ScrollToTopButton } from '../Global/ScrollToTopButton'
import { SnackBar } from '../Global/SnackBar'
import { InviteProducerModal } from '../Home/InviteProducerModal'

import { useCurrentError } from '@/store/error'
import { homeStore } from '@/store/home'
import { wrapperStore } from '@/store/wrapper'
import { scrollStore } from '@/store/scroll'

interface DesktopWrapperProps {
  children: ReactNode
}

export function DesktopWrapper({
  children,
}: DesktopWrapperProps): ReactElement {
  const [scrolled, setScrolled] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const mainDivRef = useRef<HTMLDivElement>(null)

  const { isError, errorMessage, showErrorWrapper } = useCurrentError()
  const { showInviteProducerModal, setShowInviteProducerModal } = homeStore()

  const {
    showHeader,
    showSideBarOrganizations,
    showSideBarEvents,
    showSideBarEvent,
    showScrollToTop,
    fullWidth,
  } = wrapperStore()
  const { setHandleScrollToTop, setHasScrolled } = scrollStore()

  function handleScrollToTop(): void {
    if (mainDivRef.current !== null) {
      mainDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isParentClosing) {
      setTimeout(setIsParentClosing, 200, false)
    }
  }, [isParentClosing])

  useEffect(() => {
    setHandleScrollToTop(handleScrollToTop)
    setHasScrolled(false)
  }, [])

  return (
    <div className="flex h-screen w-screen bg-dark-black p-2">
      {showSideBarOrganizations && <SideBarOrganizations />}
      <div className="flex size-full rounded-xl bg-background-main p-2">
        {showSideBarEvents && <SideBarEvents />}
        <div className="flex w-full flex-col">
          <div className="relative w-full">
            {showHeader && <Header variant="desktop" />}
            {showErrorWrapper && (
              <div className="fixed right-4 top-4 z-50 h-20 w-full max-w-[300px]">
                <SnackBar
                  isError={isError}
                  message={errorMessage}
                  isMobile={false}
                />
              </div>
            )}
          </div>
          <div className="flex size-full overflow-hidden rounded-b-md bg-[#2A2A2A]">
            {showSideBarEvent && <SideBarEvent />}
            <div className="relative flex size-full max-h-full flex-col items-center rounded-br-lg bg-[#2A2A2A]">
              <div
                ref={mainDivRef}
                className="flex size-full max-h-full flex-col items-center overflow-scroll"
                onScroll={() => {
                  if (mainDivRef.current?.scrollTop !== undefined) {
                    setScrolled(mainDivRef.current?.scrollTop > 0)
                    setHasScrolled(mainDivRef.current?.scrollTop > 0)
                  }
                }}
              >
                <div
                  className={twMerge(
                    'flex size-full flex-col items-center justify-center',
                    fullWidth ? '' : 'max-w-[800px]',
                  )}
                >
                  {children}
                  {showScrollToTop && (
                    <ScrollToTopButton
                      divRef={mainDivRef.current!}
                      scrolled={scrolled}
                      size="lg"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInviteProducerModal && (
        <InviteProducerModal
          isParentCLosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setShowInviteProducerModal, 200, false)
          }}
        />
      )}
    </div>
  )
}
