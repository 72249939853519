import { type ReactElement } from 'react'
import { GoSearch, GoX } from 'react-icons/go'

interface GrantSearchBarProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onCancel: () => void
}

export function GrantSearchBar({
  onCancel,
  ...props
}: GrantSearchBarProps): ReactElement {
  return (
    <div className="z-0 flex w-full items-center justify-between gap-2 rounded-lg border-2 border-[#defe76] bg-[#2a2a2a] px-3 py-2.5 text-white">
      <div className="flex w-full items-center gap-2">
        <GoSearch className="size-5" strokeWidth={1} />
        <input
          placeholder="Buscar cortesia..."
          className="w-full border-none bg-transparent text-sm outline-none"
          type="text"
          {...props}
        />
      </div>
      <button
        onClick={onCancel}
        className="cursor-pointer rounded-full p-0.5 transition-colors hover:bg-[#343434]"
      >
        <GoX className="size-5" strokeWidth={0.5} />
      </button>
    </div>
  )
}
