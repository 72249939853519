import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@/components/Global/Button'
import { PermissionSelector } from '@/components/Team/PermissionSelector'
import { PermissionSwitch } from '@/components/Team/PermissionSwitch'
import { PermissionsPreset } from '@/components/Team/PermissionsPreset'
import { BackButton } from '@/components/Global/BackButton'

import {
  useGetMemberEventPermissions,
  useDeleteEventRole,
  usePutUpdateEventRole,
} from '@/hooks/Team'

import { teamStore } from '@/store/team'
import { deviceStore } from '@/store/device'
import { errorStore } from '@/store/error'
import { useUserPermissions, userStore } from '@/store/user'

import { AiOutlineDelete } from 'react-icons/ai'
import { IoSaveOutline } from 'react-icons/io5'

import { IconButton } from '@/components/Global/IconButton'
import { ConfirmationModal } from '@/components/Team/ConfirmationModal'

import { CANT_UPDATE_EVENT_ROLE, CANT_DELETE_EVENT_ROLE } from '@/errors'

import { hasClearance } from '@/utils/rbac'
import { permissionsDeepEqual } from '@/utils/objects'
import { eventPermissionsDescriptions } from '@/utils/teamPermissionsDescriptions'

import { type IPermissions } from '@/types/Team'

export function EventUserPermissions(): ReactElement {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [initialPermissions, setInitialPermissions] = useState<IPermissions>()
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { user, permissions, setPermissions, event } = teamStore()
  const { username } = userStore()
  const { isMobile } = deviceStore()
  const { showError } = errorStore()
  const { organizationPermissions } = useUserPermissions()

  const canEdit = hasClearance(organizationPermissions.access, 'EDITOR')
  const isSelfEdit = user?.username === username

  const navigate = useNavigate()
  const { alias } = useParams()

  const { permissions: permissionsData, isLoading: isLoadingFetch } =
    useGetMemberEventPermissions(event?.alias ?? '', user?.username ?? '')
  const { updateEventRole, isLoading: isLoadingUpdate } = usePutUpdateEventRole(
    alias ?? '',
  )
  const { deleteEventRole, isLoading: isLoadingDelete } = useDeleteEventRole(
    alias ?? '',
  )

  async function handleUpdateEventRole(): Promise<void> {
    const response = await updateEventRole(user?.username ?? '', {
      roles: {
        event: permissions.event,
      },
    })

    if (response.status !== 200) {
      showError(CANT_UPDATE_EVENT_ROLE)
      return
    }

    setInitialPermissions(permissions)

    // TO-DO adicionar snack bar verde de sucesso
  }

  async function handleDeleteEventRole(): Promise<void> {
    const response = await deleteEventRole(user?.id ?? 0)

    if (response.status !== 200) {
      showError(CANT_DELETE_EVENT_ROLE)
      return
    }

    navigate('/team')
  }

  useEffect(() => {
    if (permissionsData !== undefined) {
      setPermissions({
        organization: permissions?.organization,
        event: permissionsData.roles.event,
      })
    }
  }, [permissionsData])

  useEffect(() => {
    if (permissionsDeepEqual(permissions, initialPermissions)) {
      setHasUnsavedChanges(false)
    } else {
      setHasUnsavedChanges(true)
    }
  }, [permissions, initialPermissions])

  return (
    <div className="relative flex size-full flex-col items-start gap-8 p-4">
      <div className="flex w-full flex-col gap-4">
        {!isMobile && (
          <div className="flex items-center justify-between">
            <BackButton />

            {!isMobile && canEdit && (
              <div className="flex h-7 items-center gap-2">
                {!isSelfEdit && (
                  <div className="h-7 w-24">
                    <IconButton
                      text="Remover"
                      Icon={(props) => <AiOutlineDelete size={20} {...props} />}
                      enabled
                      onClick={() => {
                        setShowConfirmationModal(true)
                      }}
                      variant="red"
                    />
                  </div>
                )}
                <div className="h-7 w-24">
                  <IconButton
                    text="Salvar"
                    Icon={(props) => <IoSaveOutline size={20} {...props} />}
                    enabled={hasUnsavedChanges}
                    onClick={() => {
                      void handleUpdateEventRole()
                    }}
                    variant="green"
                    isLoading={isLoadingUpdate}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="justify-betwee flex w-full items-center">
          {user !== undefined && (
            <div className="flex w-full items-center gap-4">
              <img
                src={`${import.meta.env.VITE_S3 as string}/${event?.imageKey}`}
                alt="Evento"
                className="aspect-square h-20 rounded-[5px]"
              />
              <div className="flex flex-col">
                <span className="text-2xl font-bold">{event?.name}</span>
                <div className="flex items-center gap-1">
                  <img
                    src={`${import.meta.env.VITE_S3 as string}/${
                      user.imageKey
                    }`}
                    alt="User profile image"
                    className="aspect-square size-6 rounded-full"
                  />
                  <span className="text-sm leading-3">@{user.username}</span>
                </div>
              </div>
            </div>
          )}
          {permissionsData?.roles !== undefined && !isMobile && canEdit && (
            <div className="flex w-full items-end justify-end">
              <PermissionsPreset
                permissions={permissions}
                setPermissions={setPermissions}
                isGlobalPermission={false}
              />
            </div>
          )}
        </div>

        {isMobile && canEdit && (
          <div className="flex w-full items-end justify-end">
            <PermissionsPreset
              permissions={permissions}
              setPermissions={setPermissions}
              isGlobalPermission={false}
            />
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="mb-6 flex w-full flex-col gap-2 pb-16">
          <div>
            <span className="text-lg font-black">Permissões no evento</span>
          </div>
          <div className="w-full columns-1 gap-2 desktop:columns-2">
            <div className="flex break-inside-avoid-column flex-col gap-2 pb-2 desktop:pb-0">
              <PermissionSelector
                permissions={eventPermissionsDescriptions.FINANCE.filter(
                  (p) => {
                    return p.key !== 'EDITOR'
                  },
                )}
                title="Financeiro"
                currentKey={permissions?.event?.finance}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, finance: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.ANALYTICS}
                title="Analíticos"
                currentKey={permissions?.event?.analytics}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, analytics: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.LIST}
                title="Lista"
                currentKey={permissions?.event?.list}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, list: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.TICKETS}
                title="Lotes"
                currentKey={permissions?.event?.tickets}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, tickets: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
            </div>
            <div className="flex break-inside-avoid-column flex-col gap-2">
              <PermissionSelector
                permissions={eventPermissionsDescriptions.GRANT}
                title="Cortesias"
                currentKey={permissions?.event?.grant}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, grant: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.LINK}
                title="Links"
                currentKey={permissions?.event?.link}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, link: p },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Validação de ingressos"
                active={permissions?.event?.validation}
                setActive={(state) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, validation: state },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Editar evento"
                active={permissions?.event?.edit}
                setActive={(state) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, edit: state },
                  })
                }}
                isLoading={isLoadingFetch}
                canEdit={canEdit}
              />
            </div>
          </div>
        </div>
      </div>

      {isMobile && canEdit && (
        <div className="fixed bottom-0 left-0 flex w-full items-center justify-center gap-2 bg-dark-black p-2">
          {!isSelfEdit && (
            <div className="h-12 w-full max-w-[300px]">
              <Button
                text="Remover"
                className="bg-tonal-red text-white"
                enabled={!isLoadingDelete}
                isLoading={isLoadingDelete}
                onClick={() => {
                  void handleDeleteEventRole()
                }}
              />
            </div>
          )}
          <div className="h-12 w-full max-w-[300px]">
            <Button
              text="Salvar"
              enabled={hasUnsavedChanges && !isLoadingUpdate}
              isLoading={isLoadingUpdate}
              onClick={() => {
                void handleUpdateEventRole()
              }}
            />
          </div>
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          onConfirm={() => {
            void handleDeleteEventRole()
          }}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 300, false)
            setTimeout(setShowConfirmationModal, 400, false)
          }}
          isLoading={isLoadingDelete}
          isParentClosing={isParentClosing}
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          removeFrom="event"
          eventName={event?.name}
        />
      )}
    </div>
  )
}
