import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { organizationStore } from '@/store/organization'
import { homeStore } from '@/store/home'
import { updateEventStore } from '@/store/updateEvent'
import { wrapperStore } from '@/store/wrapper'
import { useUserPermissions } from '@/store/user'

import { SideBarEventButton } from './SideBarEventButton'

import { categorizeEvents } from '@/utils/categorizeEvents'
import { hasClearance } from '@/utils/rbac'

export function SideBarEvents(): ReactElement {
  const navigate = useNavigate()

  const { currentOrganization } = organizationStore()
  const { setShowInviteProducerModal } = homeStore()
  const { updateAnyState } = updateEventStore()
  const { currentPage } = wrapperStore()
  const { organizationPermissions } = useUserPermissions()

  const { pastEvents, futureEvents } = categorizeEvents(
    currentOrganization?.events ?? [],
  )

  const canCreateEvent = hasClearance(
    organizationPermissions.eventCreation,
    true,
  )
  const canViewCommunicationCampaigns = hasClearance(
    organizationPermissions.communicationCampaign,
    'VIEWER',
  )
  const canViewTeam = hasClearance(organizationPermissions.access, 'VIEWER')
  const canEditOrganization = hasClearance(
    organizationPermissions.settings,
    true,
  )
  const canInviteProducer = hasClearance(organizationPermissions.settings, true)

  return (
    <div className="flex h-full min-w-[180px] flex-col gap-4 bg-transparent pr-2">
      <div className="flex flex-col items-start gap-1 pt-4">
        <span className="mb-1 font-bold text-white">
          {currentOrganization?.organizer.name}
        </span>
        <button
          className={twMerge(
            'text-xs text-white transition-colors duration-100 ease-in-out',
            currentPage === 'home' && 'font-bold',
          )}
          onClick={() => {
            navigate('/')
          }}
        >
          Início
        </button>
        {canEditOrganization && (
          <button
            className={twMerge(
              'text-xs text-white transition-colors duration-100 ease-in-out',
              currentPage === 'editOrganization' && 'font-bold',
            )}
            onClick={() => {
              navigate(
                `/organizations/${currentOrganization?.organizer.organizationId}`,
              )
            }}
          >
            Editar organização
          </button>
        )}
        {canInviteProducer && (
          <button
            className="line-clamp-1 text-xs text-white"
            onClick={() => {
              setShowInviteProducerModal(true)
            }}
          >
            Indicar um produtor
          </button>
        )}
        {canCreateEvent && (
          <button
            className={twMerge(
              'text-xs text-white transition-colors duration-100 ease-in-out',
              currentPage === 'createEvent' && 'font-bold',
            )}
            onClick={() => {
              navigate('/create-event', {
                state: { resetStore: 1 },
              })

              updateAnyState({ typeOperation: 'Create' })
            }}
          >
            Criar um evento
          </button>
        )}
        {canViewCommunicationCampaigns && (
          <button
            className={twMerge(
              'text-xs text-white transition-colors duration-100 ease-in-out',
              (currentPage === 'communicationCampaigns' ||
                currentPage === 'createCommunicationCampaign') &&
                'font-bold',
            )}
            onClick={() => {
              navigate('/communication-campaigns')
            }}
          >
            Envio de comunicações
          </button>
        )}
        {canViewTeam && (
          <button
            className={twMerge(
              'text-xs text-white transition-colors duration-100 ease-in-out',
              (currentPage === 'team' ||
                currentPage === 'createOrganizationRole' ||
                currentPage === 'createEventRole' ||
                currentPage === 'userGlobalPermissions' ||
                currentPage === 'userEventPermissions') &&
                'font-bold',
            )}
            onClick={() => {
              navigate('/team')
            }}
          >
            Gerenciar equipe
          </button>
        )}
      </div>
      <div className="relative h-full overflow-hidden">
        <div className="absolute left-0 top-0 h-2 w-full bg-gradient-to-b from-background-main to-transparent" />
        <div className="absolute bottom-0 left-0 h-2 w-full bg-gradient-to-t from-background-main to-transparent" />
        <div className="flex max-h-full flex-col gap-4 overflow-y-scroll py-2">
          {futureEvents.length > 0 && (
            <div className="flex flex-col">
              <span className="mb-2 text-xs font-bold text-white">
                Próximos eventos
              </span>
              {futureEvents.map((event) => {
                return <SideBarEventButton event={event} key={event.eventId} />
              })}
            </div>
          )}
          {pastEvents.length > 0 && (
            <div className="flex flex-col">
              <span className="mb-2 text-xs font-bold text-white">
                Eventos passados
              </span>
              {pastEvents.map((event) => {
                return <SideBarEventButton event={event} key={event.eventId} />
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
