import { useEffect, useState, type ReactElement } from 'react'

import { TicketSpecModal } from '../TicketSpecModal'
import { TicketSpecCard } from '../TicketSpecCard'
import { ConfirmDeletionModal } from '../ConfirmDeletionModal'
import { Button } from '@/components/Global/Button'

import { ticketStore } from '@/store/tickets'
import { deviceStore } from '@/store/device'
import { wrapperStore } from '@/store/wrapper'
import { useUserPermissions } from '@/store/user'

import { type IFollower, type ITicketSpec } from '@/types/Tickets'

import { sortFragmentedLinkedList } from '@/utils/sortTicketSpec'
import { hasClearance } from '@/utils/rbac'

import { GoPlus } from 'react-icons/go'

export function TicketsSimpleView(): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showTicketSpecModal, setShowTicketSpecModal] = useState(false)
  const [
    showConfirmTicketSpecDeletionModal,
    setShowConfirmTicketSpecDeletionModal,
  ] = useState(false)

  const { ticketsSpecs, setCurrentTicketSpec, setCurrentBatch } = ticketStore()
  const { isMobile } = deviceStore()
  const { setHeaderButton } = wrapperStore()
  const { eventPermissions } = useUserPermissions()

  const canCreateTicketSpec = hasClearance(eventPermissions.tickets, 'EDITOR')

  const dropDownData: IFollower[] = [
    { id: undefined, ticketSpecDescription: 'Nenhum' },
    ...ticketsSpecs.map((ticketsSpec) => {
      return {
        id: ticketsSpec.id,
        ticketSpecDescription: ticketsSpec.description,
      }
    }),
  ]

  const sortedItems: ITicketSpec[] = sortFragmentedLinkedList(ticketsSpecs)

  useEffect(() => {
    setHeaderButton(
      <div className="h-8 w-fit">
        {canCreateTicketSpec && (
          <Button
            enabled
            text="Adicionar ingresso"
            className="px-4 text-sm"
            onClick={() => {
              setCurrentBatch(undefined)
              setCurrentTicketSpec(undefined)
              setShowTicketSpecModal(true)
            }}
          />
        )}
      </div>,
    )
  }, [])

  return (
    <div className="h-fit w-full">
      <div className="mb-4 flex flex-col items-center gap-8">
        <ul className="flex w-full flex-col gap-10">
          {ticketsSpecs?.length === 0 ? (
            <div className="flex w-full justify-center">
              <span>Adicione um ingresso a este evento.</span>
            </div>
          ) : (
            sortedItems?.map((ticketSpec, index) => {
              const isConnectedToPrevious =
                sortedItems[index - 1]?.id ===
                (ticketSpec?.follower?.id ?? null)

              const isConnectedToNext =
                sortedItems[index + 1]?.id ===
                (ticketSpec?.follower?.id ?? null)

              const filteredDropDownData = dropDownData.filter(
                (spec) => spec.id !== ticketSpec.id,
              )
              return (
                <li key={ticketSpec.id}>
                  <TicketSpecCard
                    key={index}
                    ticketSpec={ticketSpec}
                    openTicketSpecModal={() => {
                      setCurrentTicketSpec(ticketSpec)
                      setShowTicketSpecModal(true)
                    }}
                    deleteTicketSpec={() => {
                      setCurrentBatch(undefined)
                      setCurrentTicketSpec(ticketSpec)
                      setShowConfirmTicketSpecDeletionModal(true)
                    }}
                    dropDownData={filteredDropDownData}
                    variant={isMobile ? 'small' : 'large'}
                    isConnectedToPrevious={isConnectedToPrevious}
                    isConnectedToNext={isConnectedToNext}
                  />
                </li>
              )
            })
          )}
        </ul>
        {isMobile && (
          <button
            className="fixed bottom-2 right-2 flex size-12 items-center justify-center rounded-full bg-primary-main"
            onClick={() => {
              setCurrentTicketSpec(undefined)
              setShowTicketSpecModal(true)
            }}
          >
            <GoPlus size={36} color="#232323" />
          </button>
        )}
      </div>

      {showTicketSpecModal && (
        <TicketSpecModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowTicketSpecModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showConfirmTicketSpecDeletionModal && (
        <ConfirmDeletionModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowConfirmTicketSpecDeletionModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
    </div>
  )
}
