import { useState, type ReactElement, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { errorStore } from '@/store/error'
import { grantStore, useAllGrants } from '@/store/grant'
import { useUserPermissions } from '@/store/user'
import { scrollStore } from '@/store/scroll'
import { deviceStore } from '@/store/device'
import { wrapperStore } from '@/store/wrapper'

import { useGetGrants, useGetSearchGrant } from '@/hooks/Grant'

import { Button } from '@/components/Global/Button'
import { GrantCreateModal } from '@/components/Grant/GrantCreateModal'
import { GrantInfoModal } from '@/components/Grant/GrantInfoModal'
import { GrantSelectTicketModal } from '@/components/Grant/GrantSelectTicketModal'
import { GrantUserCard } from '@/components/Grant/GrantUserCard'
import { Spinner } from '@/components/Global/Spinner'

import { type IGrant } from '@/types/Grant'

import { hasClearance } from '@/utils/rbac'

import { GoSearch } from 'react-icons/go'
import { BsArrowUpShort } from 'react-icons/bs'
import { GrantSearchBar } from '@/components/Grant/GrantSearchBar'

export function Grant(): ReactElement {
  const [searchBarIsVisible, setSearchBarIsVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [previousSearchTerm, setPreviousSearchTerm] = useState('')
  const [userGrantInfoModalIsOpen, setUserGrantInfoModalIsOpen] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [createGrantModalIsOpen, setCreateGrantModalIsOpen] = useState(false)
  const [selectTicketModalIsOpen, setSelectTicketModalIsOpen] = useState(false)

  const [currentPageGlobal, setCurrentPageGlobal] = useState(0)
  const [currentPageSearch, setCurrentPageSearch] = useState(0)
  const [noNewGrantsGlobal, setNoNewGrantsGlobal] = useState(false)
  const [noNewGrantsSearch, setNoNewGrantsSearch] = useState(false)

  const { alias } = useParams()

  const { searchUsersWithGrant } = useGetSearchGrant()
  const { getGrants } = useGetGrants()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()
  const { eventPermissions } = useUserPermissions()

  const { setHeaderButton } = wrapperStore()
  const { setAllGrants, setCurrentUser } = grantStore()
  const { hasScrolled, handleScrollToTop } = scrollStore()
  const { allGrants } = useAllGrants()

  const canEditGrant = hasClearance(eventPermissions.grant, 'EDITOR')

  async function handleGetGrants(page: number = 0): Promise<void> {
    const response = await getGrants(alias!, page)
    if (response.status === 200) {
      if (response.grants !== undefined && response.grants.length < 50) {
        setNoNewGrantsGlobal(true)
      }
      if (page === 0) setAllGrants(response?.grants ?? [])
      else setAllGrants([...(allGrants ?? []), ...(response.grants ?? [])])
    } else if (response.status !== 401) {
      showError('Erro ao buscar cortesias!')
    }
  }

  async function handleSearch(page: number = 0): Promise<void> {
    setNoNewGrantsGlobal(false)
    const response = await searchUsersWithGrant(searchTerm, alias!, page)
    if (!response.status) {
      showError('Erro ao pesquisar usuários!')
    } else if (response.users?.length === 0 && page === 0) {
      showError('Nenhum usuário encontrado!')
      if (allGrants!.length < 50) setNoNewGrantsSearch(true)
    } else if (response.users !== undefined) {
      if (response.users.length < 50) setNoNewGrantsSearch(true)
      if (page === 0) setAllGrants(response?.users)
      else setAllGrants([...(allGrants ?? []), ...(response.users ?? [])])
    }
  }

  function handleOnClick(user: IGrant): void {
    setIsParentClosing(false)
    setUserGrantInfoModalIsOpen(true)
    setCurrentUser(user)
  }

  function handleModalClose(): void {
    setIsParentClosing(false)
  }

  function handleCreateGrantModalClose(): void {
    setCreateGrantModalIsOpen(false)
    setIsParentClosing(false)
    setSelectTicketModalIsOpen(true)
  }

  function triggerNewPage(): void {
    if (isSearching) {
      void handleSearch(currentPageSearch + 1)
      setCurrentPageSearch((current) => current + 1)
    } else {
      void handleGetGrants(currentPageGlobal + 1)
      setCurrentPageGlobal((current) => current + 1)
    }
  }

  function calculateBottomOffset(
    isMobile: boolean,
    canEditGrant: boolean,
    searchBarIsVisible: boolean,
  ): number {
    if (isMobile && !canEditGrant) return searchBarIsVisible ? 82 : 28
    return searchBarIsVisible ? 134 : 80
  }

  useEffect(() => {
    setAllGrants([])
    void handleGetGrants()
  }, [])

  useEffect(() => {
    if (isSearching && searchTerm.length === 0) {
      setIsSearching(false)
      setNoNewGrantsSearch(false)
      setNoNewGrantsGlobal(false)
      setCurrentPageSearch(0)
      setCurrentPageGlobal(0)
      void handleGetGrants()
    }
  }, [isSearching, searchTerm])

  useEffect(() => {
    if (allGrants !== null && allGrants.length > 0) {
      // Instanciate the observer
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          triggerNewPage()
        }
      })

      const loadSentry = document.querySelector('#loadSentry')
      if (loadSentry !== null) intersectionObserver.observe(loadSentry)

      return () => {
        intersectionObserver.disconnect()
      }
    }
  }, [allGrants])

  useEffect(() => {
    setHeaderButton(
      <div className="h-8 w-fit">
        {canEditGrant && (
          <Button
            enabled
            text="Enviar cortesia"
            className="px-4 text-sm"
            onClick={() => {
              setIsParentClosing(false)
              setCreateGrantModalIsOpen(true)
            }}
          />
        )}
      </div>,
    )
  }, [])

  return (
    <>
      <div className="flex size-full max-w-[600px]">
        <div className="flex size-full flex-col gap-4 p-4">
          <main className="flex size-full flex-col gap-4">
            <div
              className={twMerge(
                'flex w-full flex-col transition-[padding]',
                allGrants?.length === 0 && 'h-full items-center justify-center',
                searchBarIsVisible ? 'pb-16' : 'pb-4',
                canEditGrant
                  ? isMobile && searchBarIsVisible
                    ? 'last:pb-[116px]'
                    : 'last:pb-16'
                  : null,
              )}
            >
              {allGrants !== null &&
                allGrants.length !== 0 &&
                allGrants?.map((grant) => (
                  <GrantUserCard
                    grantData={grant}
                    handleOnClick={(grant: IGrant): void => {
                      handleOnClick(grant)
                    }}
                    key={grant.id}
                  />
                ))}
              {!noNewGrantsGlobal && !noNewGrantsSearch ? (
                <div
                  id="loadSentry"
                  className="flex w-full items-center justify-center gap-4 pb-2"
                >
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-primary-main/50"
                    bottomBorderColor="border-b-primary-main"
                  />
                </div>
              ) : (
                allGrants?.length === 0 && (
                  <p className="max-w-80 text-center text-xl font-semibold">
                    Você ainda não enviou nenhuma cortesia.
                  </p>
                )
              )}
            </div>
          </main>
        </div>
      </div>
      <footer className="fixed bottom-0 z-10 flex w-full max-w-[582px] flex-col items-start rounded-t-lg bg-[#2a2a2a] pb-2 desktop:absolute desktop:flex-row desktop:pb-0">
        <AnimatePresence>
          {allGrants?.length !== 0 && searchBarIsVisible && (
            <motion.div
              key="search-bar-container"
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: '-100%', opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ duration: 0.15, delay: 0.2 }}
              className="absolute w-full bg-[#2a2a2a] px-2 pb-2"
            >
              <div className="absolute -top-10 left-0 -z-10 h-20 w-full px-2">
                <div className="size-full bg-gradient-to-b from-transparent to-[#2a2a2a]" />
              </div>
              <GrantSearchBar
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchTerm !== previousSearchTerm) {
                    setNoNewGrantsGlobal(false)
                    setNoNewGrantsSearch(false)
                    setCurrentPageGlobal(0)
                    setCurrentPageSearch(0)
                    setIsSearching(true)
                    setPreviousSearchTerm(searchTerm)
                    void handleSearch()
                  }
                }}
                onCancel={() => {
                  if (searchTerm !== '') {
                    setSearchTerm('')
                    setNoNewGrantsGlobal(false)
                    setNoNewGrantsSearch(false)
                    setCurrentPageGlobal(0)
                    setCurrentPageSearch(0)
                    setIsSearching(true)
                    void handleSearch()
                  }
                  setSearchBarIsVisible(false)
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
        {canEditGrant && (
          <div className="w-full px-2">
            <Button
              className="block py-2.5 text-base desktop:hidden"
              enabled
              onClick={() => {
                setIsParentClosing(false)
                setCreateGrantModalIsOpen(true)
              }}
              text="Criar cortesia"
            />
          </div>
        )}
      </footer>
      {allGrants?.length !== 0 && (
        <motion.div
          key="page-btn-container"
          initial={{ height: 48, bottom: !canEditGrant && isMobile ? 28 : 80 }}
          animate={{
            height: hasScrolled ? 102 : 48,
            bottom: calculateBottomOffset(
              isMobile,
              canEditGrant,
              searchBarIsVisible,
            ),
          }}
          transition={{
            delay: hasScrolled ? 0 : 0.2,
            duration: 0.2,
            ease: 'easeInOut',
          }}
          className="fixed bottom-[134px] right-4 z-30 flex flex-col justify-start gap-1.5 pt-0 desktop:absolute desktop:bottom-[4.5rem] desktop:pt-12"
        >
          <button
            onClick={() => {
              setSearchBarIsVisible((prev) => !prev)
            }}
            className=" flex size-12 shrink-0 items-center justify-center rounded-full bg-primary-main transition-colors hover:bg-primary-main/90"
          >
            <GoSearch strokeWidth={0.625} className="size-5 text-dark-black" />
          </button>
          <AnimatePresence>
            {hasScrolled && (
              <motion.button
                onClick={handleScrollToTop}
                key="local-scroll-btn"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.2, delay: 0 } }}
                transition={{ delay: 0.2, duration: 0.2 }}
                className={twMerge(
                  'flex size-12 shrink-0 items-center justify-center rounded-full bg-white transition-colors hover:bg-[#f3f3f3]',
                )}
              >
                <BsArrowUpShort className="size-7" color="#181818" />
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      )}
      {userGrantInfoModalIsOpen && (
        <GrantInfoModal
          closeModal={(): void => {
            setIsParentClosing(true)
            setTimeout(setUserGrantInfoModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(handleModalClose, 400)
          }}
        />
      )}
      {createGrantModalIsOpen && (
        <GrantCreateModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setCreateGrantModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(handleCreateGrantModalClose, 400)
          }}
        />
      )}
      {selectTicketModalIsOpen && (
        <GrantSelectTicketModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setSelectTicketModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setSelectTicketModalIsOpen, 400, true)
          }}
        />
      )}
    </>
  )
}
