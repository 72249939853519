import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Tooltip } from 'react-tooltip'

import { AreaChart } from '@/components/Chart/AreaChart'
import { OnFire } from '@/components/Global/OnFire'

import { type Event } from '@/types/Organization'

import { formatNumber, numberToReais } from '@/utils/formatNumber'
import { hasClearance } from '@/utils/rbac'

import { LuInfo } from 'react-icons/lu'

interface HomeSectionDesktopProps {
  events?: Event[]
  title: string
  isPastEvent: boolean
}

export function HomeSectionDesktop({
  events,
  title,
  isPastEvent,
}: HomeSectionDesktopProps): ReactElement {
  const navigate = useNavigate()

  return (
    <div className="flex w-full flex-col gap-2">
      <span className="text-2xl font-bold">{title}</span>
      <div className="flex flex-wrap gap-4">
        {events?.map((event) => {
          const dataArea = event.revenueByDay.map((value) => {
            return {
              pv: value,
            }
          })
          const canViewFinance = hasClearance(event.roles.finance, 'VIEWER')
          const balance =
            event.revenue * (1 + event.cashback) - event.withdrawals

          return (
            <button
              key={event.eventId}
              className={twMerge(
                'transition-color flex w-[calc(50%-8px)] min-w-[350px] cursor-not-allowed flex-col gap-2 rounded-md border border-transparent bg-dark-black p-4 duration-100 ease-in-out',
                event.status === 'APPROVED' &&
                  'cursor-pointer hover:border-primary-main',
              )}
              onClick={() => {
                if (event.status === 'APPROVED')
                  navigate(`/events/${event.alias}`)
              }}
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                  <img
                    src={`${import.meta.env.VITE_S3}/${
                      event.backgroundArtImageKey
                    }`}
                    alt="Event image"
                    className="aspect-square size-10 rounded-full"
                  />
                  <div className="flex flex-col justify-center">
                    <span className="line-clamp-1 text-left font-bold leading-5">
                      {event.name}
                    </span>
                    <span className="text-left text-xs text-text-secondary">
                      {event.date}
                    </span>
                  </div>
                </div>
                {event.status === 'APPROVED' ? (
                  <div className="flex items-center gap-2">
                    {event.onFire && <OnFire variant="simple" />}
                    {event.eventType === 'CLOSE_FRIENDS' && (
                      <div className="flex h-fit items-center justify-center text-nowrap rounded-full bg-white px-2 py-0.5 text-2xs font-black text-dark-black">
                        CLOSE FRIENDS
                      </div>
                    )}
                  </div>
                ) : event.status === 'PENDING' ? (
                  <div className="flex items-center gap-2 rounded-sm bg-white px-4 py-1">
                    <span className="text-nowrap text-2xs font-medium text-dark-black">
                      Em análise
                    </span>
                    <a
                      className="text-xs font-bold"
                      data-tooltip-id="pendind-approval-tooltip"
                      data-tooltip-content="O seu evento será analisado em até duas horas."
                    >
                      <LuInfo color="black" size={12} />
                    </a>
                    <Tooltip id="pendind-approval-tooltip" />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 rounded-sm bg-tonal-red px-4 py-1">
                    <span className="text-nowrap text-2xs font-medium text-white">
                      Bloqueado
                    </span>
                    <a
                      className="text-xs font-bold"
                      data-tooltip-id="pendind-approval-tooltip"
                      data-tooltip-content="Seu evento violou uma ou mais políticas da Gandaya."
                    >
                      <LuInfo color="white" size={12} />
                    </a>
                    <Tooltip id="pendind-approval-tooltip" />
                  </div>
                )}
              </div>
              {event.status === 'APPROVED' && (
                <div
                  className={twMerge(
                    'relative flex w-full justify-between gap-4',
                  )}
                >
                  <div
                    className={twMerge(
                      'h-[105px] ',
                      canViewFinance ? 'w-[80%]' : 'w-full',
                    )}
                  >
                    <AreaChart data={dataArea} />
                  </div>
                  {canViewFinance && (
                    <div className="flex min-w-[110px] flex-col gap-1">
                      <div className="flex flex-col items-start">
                        <span className="text-2xs font-light leading-3">
                          Faturamento total
                        </span>
                        <span className="line-clamp-1 font-bold leading-5">
                          {numberToReais(event.revenue, 2)}
                        </span>
                      </div>
                      <>
                        <div className="flex flex-col items-start">
                          <span className="text-2xs font-light leading-3 ">
                            Ingressos
                          </span>
                          <span className="line-clamp-1 font-bold leading-5">
                            {formatNumber(event.numberOfSoldTickets.total)}
                          </span>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="text-2xs font-light leading-3">
                            {isPastEvent
                              ? 'Saldo em conta'
                              : 'Vendas nas últimas 2h'}
                          </span>
                          <span className="line-clamp-1 font-bold leading-5">
                            {isPastEvent
                              ? numberToReais(balance, 2)
                              : event.numberOfSoldTickets.lastTwoHours}
                          </span>
                        </div>
                      </>
                    </div>
                  )}
                </div>
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}
