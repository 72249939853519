import { type ReactElement } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { updateEventStore } from '@/store/updateEvent'
import { useUserPermissions } from '@/store/user'

import { LuCalendarPlus } from 'react-icons/lu'
import { TbSpeakerphone } from 'react-icons/tb'
import { FaPeopleGroup } from 'react-icons/fa6'
import { FaRegEdit } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'

import { hasClearance } from '@/utils/rbac'
import { useCurrentOrganization } from '@/store/organization'

interface HomeIconMenuProps {
  setShowInviteProducerModal: (state: boolean) => void
}

export function HomeIconMenu({
  setShowInviteProducerModal,
}: HomeIconMenuProps): ReactElement {
  const navigate = useNavigate()

  const { updateAnyState } = updateEventStore()
  const { organizationPermissions } = useUserPermissions()
  const { currentOrganization } = useCurrentOrganization()

  const canCreateEvent = hasClearance(
    organizationPermissions.eventCreation,
    true,
  )
  const canViewCommunicationCampaigns = hasClearance(
    organizationPermissions.communicationCampaign,
    'VIEWER',
  )
  const canViewTeam = hasClearance(organizationPermissions.access, 'VIEWER')
  const canEditOrganization = hasClearance(
    organizationPermissions.settings,
    true,
  )
  const canInviteProducer = hasClearance(organizationPermissions.settings, true)

  return (
    <div className="relative mt-4 w-full">
      <div className="flex size-full justify-start gap-4 overflow-y-hidden overflow-x-scroll scroll-smooth px-4">
        <div className="flex gap-2">
          {canCreateEvent && (
            <div
              className="flex w-24 cursor-pointer flex-col items-center gap-3"
              onClick={() => {
                navigate('/create-event', {
                  state: { resetStore: 1 },
                })

                updateAnyState({ typeOperation: 'Create' })
              }}
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <LuCalendarPlus size={32} />
              </div>
              <span className="h-8 text-center text-sm font-medium leading-4">
                Criar um evento
              </span>
            </div>
          )}
          {canEditOrganization && (
            <Link
              to={`/organizations/${currentOrganization?.organizer.organizationId}`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer">
                <FaRegEdit size={28} />
              </div>
              <span className="text-center text-sm font-medium leading-4">
                Editar organização
              </span>
            </Link>
          )}
          {canInviteProducer && (
            <div
              className="flex w-24 flex-col items-center gap-3"
              onClick={() => {
                setShowInviteProducerModal(true)
              }}
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer">
                <FiSend size={28} />
              </div>
              <span className="text-center text-sm font-medium leading-4">
                Indicar um produtor
              </span>
            </div>
          )}
          {canViewCommunicationCampaigns && (
            <Link
              to={`/communication-campaigns`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <TbSpeakerphone size={36} />
              </div>
              <span className="line-clamp-2 text-center text-sm font-medium leading-4">
                Envio de comunicações
              </span>
            </Link>
          )}
          {canViewTeam && (
            <Link
              to={`/team`}
              className="flex w-24 flex-col items-center gap-3"
            >
              <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
                <FaPeopleGroup size={32} />
              </div>
              <span className="line-clamp-2 text-center text-sm font-medium leading-4">
                Gerenciar equipe
              </span>
            </Link>
          )}
        </div>
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-background-main" />
      <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-background-main" />
    </div>
  )
}
